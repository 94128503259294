<template>
    <div class="vendor-display-setting">
        <edit :fs="vendorDisplayFields" api="settings" id="vendor-display" title="Vendor Display" @saved="saved"></edit>
    </div>
</template>
<script>
import Edit from "@/components/Edit";
import { mapGetters } from "vuex";
export default {
    components: {
        Edit
    },
    data() {
        return {};
    },
    computed: {
        vendorDisplayFields() {
            return [{ heading: "Vendor Display", type: "boolean", data: "value" }];
        }
    },
    methods: {
        saved() {
            this.$store.dispatch("setting/loadVendorDisplay");
        }
    },
    mounted() {},
    created() {}
};
</script>
<style lang="less">
.vendor-display-setting {
    max-width: 1000px;
    margin: auto;
}
</style>
